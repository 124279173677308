import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class NewReportProvider extends HttpRequest {
  getNewReport (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/new-report', query)
  }

  getNewReportByProductTags (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/new-report/product-tag', query)
  }

  getSalesProduct (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/product-sales', query)
  }

  getPosSalesProduct (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/pos-product-sales/warehouse', query)
  }

  getExportProTagFilter (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/export-pro/product-tag-filter', query)
  }

  getExportPro (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/export-pro', query)
  }

  getExportProWarehouse (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/export-pro/warehouse', query)
  }

  getNewReportSummary (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/summary', query)
  }

  getNewNationality (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/summary/nationality', query)
  }

  getNewGender (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/summary/gender', query)
  }

  getSummaryShippingCountry (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/summary/shipping-country', query)
  }

  getNewReportSummaryWarehouse (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/summary/warehouse', query)
  }

  getCashierReport (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/cashier', query)
  }

  getAdminReport (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/admin', query)
  }

  getNewReportPos (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/new-report/warehouse', query)
  }

  getFFMSummary (query) {
    this.setHeader(getAuthToken())
    return this.get('/new-report/ffm-summary/warehouse', query)
  }

  getPosDailyReport (query) {
    this.setHeader(getAuthToken())
    return this.get('/pos-daily-reports', query)
  }
}

export default NewReportProvider
